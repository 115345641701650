import React, { useState, useRef, useEffect } from 'react'
import { Paper, Button, Typography, Box } from '@mui/material'

import useAlertsPage from './useAlertsPage'

import AlertsTable from '../../organisms/AlertsTable'
import { useReactToPrint } from 'react-to-print'

import { fullDateTimeFormat } from '../../../common/utils/dates'

const AlertsPage = () => {
  const { building, loading } = useAlertsPage()

  const [alertsTableProps, setAlertsTableProps] = useState({}) // We need this state to pass props from the AlertsTable back
  // here

  const onClickHandler = () => {
    // Open a new tab to show a printable listing of the AlertsTable. The state variable alertsTableProps
    // is set in the AlertsTable component to contain the query and eventFilter values, which are inserted
    // as search parameters in the URL in the new tab

    const url = new URL(window.location.href)
    url.searchParams.set('print', true)

    alertsTableProps.query ? url.searchParams.set('query', alertsTableProps.query) : null
    alertsTableProps.eventFilter ? url.searchParams.set('eventFilter', alertsTableProps.eventFilter) : null
    alertsTableProps.severityFilter
      ? url.searchParams.set('severityFilter', alertsTableProps.severityFilter)
      : null

    window.open(url, '_blank') // Open same URL with extra parameters
  }

  const urlParams = new URLSearchParams(window.location.search)
  const isPrinting = urlParams.get('print')

  const printRef = useRef(null)
  const handlePrint = useReactToPrint({
    contentRef: printRef,
    bodyClass: 'report-printable' // Needed to make components printable
  })

  if (loading) return <></>
  return (
    <>
      {isPrinting ? (
        <Button
          onClick={() => {
            handlePrint()
          }}
          sx={{ m: '1em' }}
          variant='contained'
          className='btn btn-info btn-md float-right' // Due to the float-right class
        >
          Print
        </Button>
      ) : (
        <Button
          onClick={onClickHandler}
          sx={{ m: '1em' }}
          variant='contained'
          className='btn btn-info btn-md float-right' // Due to the float-right class
        >
          Printable Listing
        </Button>
      )}
      <Paper sx={{ p: 4 }} ref={printRef}>
        <Box sx={{ mt: '2em', mb: '1em' }} component='div' className='print-only'>
          {/* Headers in the printed report. */}
          <img src={require('../../../../assets/images/cirkuit_logo_new.png')} alt='CirkuitLogo' />{' '}
          {/* cirkuit logo */}
          <Typography className='float-right'>{fullDateTimeFormat(Date())}</Typography> {/* today's date */}
        </Box>
        <AlertsTable
          building={building}
          showSearchBar={!isPrinting}
          showEventTypeFilter={!isPrinting}
          showSeverityFilter={!isPrinting}
          isPrinting={isPrinting}
          alertsTableProps={alertsTableProps}
          setAlertsTableProps={setAlertsTableProps}
        />
      </Paper>
    </>
  )
}

export default AlertsPage

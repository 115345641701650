import React, { useEffect, useState } from 'react'
import { idxToColor } from '../../../common/utils/floorLayouts'

const FloorCell = ({ currentFloor, currentUnit, floorUnits, grid, row, col, isMouseDown }) => {
  const floorCellId = `floorcell-${row * grid[0].length + col}`
  let cell = document.getElementById(floorCellId)

  const currentUnitEmpty = () => {
    return Object.keys(currentUnit).length === 0
  }

  const classes = {
    clickable: grid[row][col] === null || grid[row][col].id === currentUnit?.id,
    clicked: grid[row][col] !== null
  }

  useEffect(() => {
    if (cell) cell.className = getClasses()
    cell = document.getElementById(floorCellId)
  }, [grid])

  const handleClick = () => {
    classes.clicked = !classes.clicked
    cell.className = getClasses()
    if (classes.clickable) {
      grid[row][col] = grid[row][col] ? null : !currentUnitEmpty() ? currentUnit : -1
      if (classes.clicked) {
        console.log(grid[row][col])
        if (floorUnits[grid[row][col]?.id]?.idx)
          cell.style.backgroundColor = idxToColor(floorUnits[grid[row][col].id].idx)
        else cell.style.backgroundColor = 'red'
      } else cell.style.backgroundColor = '#abafb3'
    }
  }

  const getClasses = () => {
    let classesStr = 'floorcell'
    for (let cls of Object.keys(classes)) {
      if (classes[cls]) classesStr += ` ${cls}`
    }
    return classesStr
  }

  return (
    <td
      id={floorCellId}
      className={getClasses()}
      onMouseDown={handleClick}
      onMouseEnter={() => {
        if (isMouseDown.current) handleClick()
      }}
      style={
        floorUnits[grid[row][col]?.id]?.idx >= 0
          ? { backgroundColor: idxToColor(floorUnits[grid[row][col].id].idx) }
          : {}
      }
    />
  )
}

export default FloorCell

import React, { useEffect, useState } from 'react'
import { useFetchOccupancyDataQuery } from '../GraphQl/gql.generated'
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { StyledTableCell } from '../../../../common/theme/components/tables/StyledTableCell'
import { isEmpty, map } from 'lodash'
import { StyledTableRow } from '../../../../common/theme/components/tables/StyledTableRow'
import { fetchCriteria } from '../../../../common/utils/reports'
import { getWorkdaysBetween, getWeekdayFromNumber } from '../../../../common/utils/dates'
import TooltipIcon from '../../../atoms/TooltopIcon'
import DayBreakDownPopover from './DayBreakDownPopover'

const OccupancySummaryCard = ({ filterCriteria, reportToken, user }) => {
  const [summaryData, setSummaryData] = useState({})
  const [occupancyData, setOccupancyData] = useState({})
  const { data: data, loading: loading } = useFetchOccupancyDataQuery({
    variables: {
      filters: fetchCriteria(filterCriteria),
      reportToken
    }
  })

  // Some studies show average washroom entries per day per person in an office is 3
  // To account for some level of tailgaiting coming in and out, we lower the divisor
  // to push the numbers up a bit. This is only an estimate at this point
  // The output of this has been validated against 3 different floors of buildings at Aspen Properties
  // We are likely within 10-15% margin for error.
  const washroomEntryDivisor = 2.8

  const avgOccupancyTooltip =
    'Based on research data showing average 3 office washroom entries per day per person (excludes weekend data). Calculations are adjusted to account for tailgate entries.'

  const findMinMaxDoorCounts = (weekdayTotalCounts) => {
    if (isEmpty(weekdayTotalCounts)) return null

    let minEntry = weekdayTotalCounts[0]
    let maxEntry = weekdayTotalCounts[0]

    weekdayTotalCounts.forEach((entry) => {
      if (entry.door_open_count < minEntry.door_open_count) minEntry = entry
      if (entry.door_open_count > maxEntry.door_open_count) maxEntry = entry
    })

    return {
      minEntryDay: getWeekdayFromNumber(minEntry.weekday),
      maxEntryDay: getWeekdayFromNumber(maxEntry.weekday)
    }
  }

  // This method takes a  guess at work from home days by looking for days where washroom entries
  // are 10% lower than the average over a week. It tends to produce fairly good guesses (though it's not perfect)
  const findPredictedWfhDays = (door_data) => {
    const numbers = door_data.map((values, _) => values.door_open_count)
    const mean = numbers.reduce((a, b) => a + b, 0) / numbers.length
    const filteredNumbers = numbers.filter((num) => num < mean - mean * 0.1)

    const predictedDays = door_data
      .filter((values, _) => filteredNumbers.includes(values.door_open_count))
      .map((wfhDay) => getWeekdayFromNumber(wfhDay.weekday))

    return isEmpty(predictedDays) ? 'None' : predictedDays.join(', ')
  }

  useEffect(() => {
    if (data) {
      const buildingFloorCounts = data.fetchOccupancyData.buildingFloorCounts
      setOccupancyData(data.fetchOccupancyData)
      const buildingSummaryData = {}
      for (let building in buildingFloorCounts) {
        let sumMens = 0
        let sumWomens = 0
        for (let key in buildingFloorCounts[building]) {
          sumMens += buildingFloorCounts[building][key]['mens_wc_count']
          sumWomens += buildingFloorCounts[building][key]['womens_wc_count']
        }
        const sortedFloors = Object.entries(buildingFloorCounts[building]).sort(
          (a, b) => b[1].total_wc_count - a[1].total_wc_count
        )

        const topBottomThreshold = Math.floor(Object.keys(sortedFloors).length / 4)

        const totals = { '1.0': 0, '2.0': 0, '3.0': 0, '4.0': 0, '5.0': 0 }

        Object.values(buildingFloorCounts[building]).forEach((floor) => {
          floor.weekday_count.forEach((day) => {
            totals[day.weekday] += day.door_open_count
          })
        })

        const buildingWeekdayTotals = [
          { weekday: '1.0', door_open_count: totals['1.0'] },
          { weekday: '2.0', door_open_count: totals['2.0'] },
          { weekday: '3.0', door_open_count: totals['3.0'] },
          { weekday: '4.0', door_open_count: totals['4.0'] },
          { weekday: '5.0', door_open_count: totals['5.0'] }
        ]

        buildingSummaryData[building] = {
          top5Floors: Object.fromEntries(sortedFloors.slice(0, topBottomThreshold)),
          bottom5Floors: Object.fromEntries(sortedFloors.slice(-topBottomThreshold)),
          sumMens: sumMens,
          sumWomens: sumWomens,
          allEntriesCount: sumMens + sumWomens,
          buildingWeekdayTotals: buildingWeekdayTotals
        }
      }
      setSummaryData(buildingSummaryData)
      console.log(buildingSummaryData)
    }
  }, [data])

  const totalReportDays = getWorkdaysBetween(filterCriteria?.startDate, filterCriteria?.endDate)

  return (
    <>
      <Paper sx={{ mt: 2, p: 4 }}>
        <h4 align={'center'}>Occupancy Summary</h4>
        <TableContainer>
          <Table aria-label='collapsible table'>
            {loading && (
              <TableBody>
                <TableRow hover>
                  <TableCell align='center' colSpan={8} scope='row'>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {!loading && !isEmpty(summaryData) && (
              <>
                <TableHead>
                  <TableRow key='header'>
                    <StyledTableCell>Building</StyledTableCell>
                    <StyledTableCell>Busiest Floors</StyledTableCell>
                    <StyledTableCell>Quietest Floors</StyledTableCell>
                    <StyledTableCell>Busiest Day</StyledTableCell>
                    <StyledTableCell>Quietest Day</StyledTableCell>
                    <StyledTableCell>Demographics</StyledTableCell>
                    <StyledTableCell>
                      Est. Avg. Daily Occupancy
                      <TooltipIcon title={avgOccupancyTooltip} textFontSize={14} />
                    </StyledTableCell>
                    <StyledTableCell>Predicted Work From Home Day(s)</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(summaryData, (value, key) => {
                    return (
                      <StyledTableRow hover key={key}>
                        <StyledTableCell>{occupancyData.buildingNames[key]}</StyledTableCell>
                        {value.allEntriesCount > 0 && (
                          <>
                            <StyledTableCell>{Object.keys(value.top5Floors).join(', ')}</StyledTableCell>
                            <StyledTableCell>{Object.keys(value.bottom5Floors).join(', ')}</StyledTableCell>
                            <StyledTableCell>
                              {
                                findMinMaxDoorCounts(occupancyData.buildingWeekdayTotalEntries[key])
                                  ?.maxEntryDay
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              {
                                findMinMaxDoorCounts(occupancyData.buildingWeekdayTotalEntries[key])
                                  ?.minEntryDay
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              Men: {Math.round((100 * value.sumMens) / value.allEntriesCount)}%
                              &nbsp;&nbsp;&nbsp; Women:{' '}
                              {Math.round((100 * value.sumWomens) / value.allEntriesCount)}%
                            </StyledTableCell>
                            <StyledTableCell>
                              {Math.round(value.allEntriesCount / totalReportDays / washroomEntryDivisor)}
                            </StyledTableCell>
                            <StyledTableCell>
                              <DayBreakDownPopover
                                baseText={
                                  value.allEntriesCount < 300
                                    ? 'Not enough data'
                                    : findPredictedWfhDays(value.buildingWeekdayTotals)
                                }
                                weekdaysValues={{ weekday_count: value.buildingWeekdayTotals }}
                              />
                            </StyledTableCell>
                          </>
                        )}
                        {value.allEntriesCount === 0 && (
                          <StyledTableCell colSpan={7} sx={{ pl: '40%', fontWeight: 900 }}>
                            No Data Available
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    )
                  })}
                </TableBody>
              </>
            )}
            {!loading && isEmpty(summaryData) && (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align='center' colSpan={8} scope='row'>
                    <Typography component={'h4'}>No Data Available</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>

      {loading && (
        <Paper sx={{ mt: 2, p: 4 }}>
          <h4 align={'center'}>Occupancy Details</h4>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress />
          </Box>
        </Paper>
      )}
      {!loading &&
        !isEmpty(summaryData) &&
        Object.keys(occupancyData.buildingNames).map((buildingId) =>
          !isEmpty(occupancyData.buildingWeekdayTotalEntries[buildingId]) ? (
            <Paper sx={{ mt: 2, p: 4 }} key={buildingId}>
              <h4 align={'center'}>{occupancyData.buildingNames[buildingId]} Occupancy Details</h4>
              <TableContainer>
                <Table aria-label='table'>
                  <TableHead>
                    <TableRow key='header'>
                      <StyledTableCell>Floor</StyledTableCell>
                      <StyledTableCell>Men's WC Entries</StyledTableCell>
                      <StyledTableCell>Women's WC Entries</StyledTableCell>
                      <StyledTableCell>Demographics</StyledTableCell>
                      <StyledTableCell>
                        Total WC Entries
                        <TooltipIcon
                          title='1 entry is defined as the door being opened twice. Tailgaters will skew this data slightly, so the total number entries is an approximation with some margin for error.'
                          textFontSize={14}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        Est. Avg. Daily Occupancy
                        <TooltipIcon title={avgOccupancyTooltip} textFontSize={14} />
                      </StyledTableCell>
                      <StyledTableCell>Busiest Day</StyledTableCell>
                      <StyledTableCell>Quietest Day</StyledTableCell>
                      <StyledTableCell>Predicted Work From Home Day(s)</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(occupancyData.buildingFloorCounts[buildingId], (value, key) => {
                      return (
                        <StyledTableRow hover key={key + buildingId}>
                          <StyledTableCell>{key}</StyledTableCell>
                          <StyledTableCell>{value.mens_wc_count}</StyledTableCell>
                          <StyledTableCell>{value.womens_wc_count}</StyledTableCell>
                          <StyledTableCell>
                            {value.total_wc_count > 0 ? (
                              <>
                                Men: {Math.round((100 * value.mens_wc_count) / value.total_wc_count)}%
                                &nbsp;&nbsp;&nbsp; Women:{' '}
                                {Math.round((100 * value.womens_wc_count) / value.total_wc_count)}%
                              </>
                            ) : (
                              'N/A'
                            )}
                          </StyledTableCell>
                          <StyledTableCell>{value.total_wc_count}</StyledTableCell>
                          <StyledTableCell>
                            {Math.round(value.total_wc_count / totalReportDays / washroomEntryDivisor)}
                          </StyledTableCell>
                          <StyledTableCell>
                            <DayBreakDownPopover
                              baseText={findMinMaxDoorCounts(value.weekday_count)?.maxEntryDay}
                              weekdaysValues={value}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <DayBreakDownPopover
                              baseText={findMinMaxDoorCounts(value.weekday_count)?.minEntryDay}
                              weekdaysValues={value}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {value.total_wc_count > 0 ? (
                              <DayBreakDownPopover
                                baseText={
                                  value.total_wc_count < 60
                                    ? 'Not enough data'
                                    : findPredictedWfhDays(value.weekday_count)
                                }
                                weekdaysValues={value}
                              />
                            ) : (
                              'Not enough data'
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : null
        )}
    </>
  )
}

export default OccupancySummaryCard

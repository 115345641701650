import React, { useState } from 'react'
import { Paper, Table, TableContainer, TableHead, TableBody, TableRow } from '@mui/material'
import { StyledTableCell } from '../../../../common/theme/components/tables/StyledTableCell'
import { StyledTableRow } from '../../../../common/theme/components/tables/StyledTableRow'
import { Bar } from 'react-chartjs-2'
import { useFetchOccupantFlowDataQuery } from '../GraphQl/gql.generated'
import { fetchCriteria } from '../../../../common/utils/reports'
import BarWithTable from './BarWithTable'
import { capitalize } from 'lodash'

const OccupantFlowSummary = ({ filterCriteria, reportToken }) => {
  const [chartData, setChartData] = useState({})
  const [labels, setLabels] = useState({})

  const { data: resultData, loading } = useFetchOccupantFlowDataQuery({
    variables: { filters: fetchCriteria(filterCriteria), reportToken },
    onCompleted: (data) => {
      setChartData(data.fetchOccupantFlowData.chart_dataset.datasets)
      setLabels(data.fetchOccupantFlowData.chart_dataset.labels)
      console.log(data)
    }
  })

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        stacked: false,
        stepSize: 1
      },
      x: {
        stacked: false
      }
    }
  }

  return (
    <>
      {Object.keys(chartData).map((groupId) => (
        <Paper sx={{ mt: 2, mb: 2, p: 4 }} key='1'>
          <h4 align={'center'}>{chartData[groupId].name} Occupant Flow Summary</h4>
          {['averages', 'totals'].map((aggregate) => (
            <>
              {['hourly', 'daily'].map((timeSlice) => (
                <>
                  <br />
                  <br />
                  <h6 align={'center'}>
                    {capitalize(timeSlice)} {capitalize(aggregate)}
                  </h6>
                  <BarWithTable
                    timeLabels={labels[timeSlice]}
                    datasets={[
                      {
                        label: chartData[groupId].in_label,
                        data: Object.values(chartData[groupId][aggregate][timeSlice].in)
                      },
                      {
                        label: chartData[groupId].out_label,
                        data: Object.values(chartData[groupId][aggregate][timeSlice].out)
                      }
                    ]}
                  />
                </>
              ))}
            </>
          ))}
        </Paper>
      ))}
    </>
  )
}

export default OccupantFlowSummary

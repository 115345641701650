import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOccupancyFlowDataQueryVariables = Types.Exact<{
  timeRange: Types.Scalars['Int'];
}>;


export type GetOccupancyFlowDataQuery = { __typename?: 'Query', getOccupancyFlow: unknown };


export const GetOccupancyFlowDataDocument = gql`
    query GetOccupancyFlowData($timeRange: Int!) {
  getOccupancyFlow(timeRange: $timeRange)
}
    `;

/**
 * __useGetOccupancyFlowDataQuery__
 *
 * To run a query within a React component, call `useGetOccupancyFlowDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOccupancyFlowDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOccupancyFlowDataQuery({
 *   variables: {
 *      timeRange: // value for 'timeRange'
 *   },
 * });
 */
export function useGetOccupancyFlowDataQuery(baseOptions: Apollo.QueryHookOptions<GetOccupancyFlowDataQuery, GetOccupancyFlowDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOccupancyFlowDataQuery, GetOccupancyFlowDataQueryVariables>(GetOccupancyFlowDataDocument, options);
      }
export function useGetOccupancyFlowDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOccupancyFlowDataQuery, GetOccupancyFlowDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOccupancyFlowDataQuery, GetOccupancyFlowDataQueryVariables>(GetOccupancyFlowDataDocument, options);
        }
export type GetOccupancyFlowDataQueryHookResult = ReturnType<typeof useGetOccupancyFlowDataQuery>;
export type GetOccupancyFlowDataLazyQueryHookResult = ReturnType<typeof useGetOccupancyFlowDataLazyQuery>;
export type GetOccupancyFlowDataQueryResult = Apollo.QueryResult<GetOccupancyFlowDataQuery, GetOccupancyFlowDataQueryVariables>;
import { get } from 'lodash'

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
// import { useUpdateValveMutation } from '../GraphQl/gql.generated'
import {
  useCreateValveMutation,
  useUpdateValveMutation,
  FetchValvesDocument
} from '../../pages/Valves/GraphQl/gql.generated'
import { useFetchProjectListQuery } from '../../organisms/GatewaysTable/GraphQl/gql.generated'
import { useFetchUnitsQuery } from '../../pages/dashboards/components/DashboardFiltersPanel/gql.generated'
import { useUnlinkValveSensorMutation } from './GraphQl/gql.generated'

const schema = yup
  .object({
    make: yup.string().required('Make is required'),
    model: yup.string().required('Model is required'),
    location: yup.string().required('Location is required'),
    hardwareId: yup.string().required('Hardware ID is required'),
    buildingId: yup.string().required('Building ID is required'),
    unitId: yup.string().required('Zone is required'),
    projectId: yup.string().nullable()
  })
  .required()

const useCreateEditValve = ({ valve, building, context, alertState, setAlertState }) => {
  const [localValve, setLocalValve] = useState(valve)
  const buildingId = context === 'create' ? building.id : localValve.buildingId

  useEffect(() => {
    setLocalValve(valve)
  }, [valve])

  const { data: projectData } = useFetchProjectListQuery({
    variables: {
      buildingId: buildingId
    }
  })

  const { data: unitsData } = useFetchUnitsQuery({
    variables: {
      buildingIds: [buildingId]
    }
  })

  const projects = get(projectData, ['fetchProjects', 'collection'], [])
  const units = get(unitsData, ['fetchUnits'], [])

  const defaultValues =
    context === 'edit'
      ? {
          make: localValve.make,
          model: localValve.model,
          location: localValve.location,
          // serialNumber: localValve.serialNumber,
          hardwareId: localValve.hardwareId,
          buildingId: localValve.buildingId,
          inUse: localValve.inUse,
          projectId: get(localValve, ['activeProject', 'id'], ''),
          unitId: localValve.unit.id
        }
      : {
          make: '',
          model: '',
          location: '',
          hardwareId: '',
          projectId: '',
          buildingId: building.id,
          unitId: ''
        }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const [createValve] = useCreateValveMutation()
  const [editValve] = useUpdateValveMutation()
  const onSubmit = (input) => {
    if (context === 'edit') {
      editValve({
        variables: {
          input: {
            id: localValve.id,
            location: input.location,
            unitId: input.unitId,
            make: input.make,
            model: input.model,
            projectId: input.projectId
          }
        },
        onCompleted: (data) => {
          const updatedValve = data.updateValve
          setLocalValve(updatedValve)
          reset({
            make: updatedValve.make,
            model: updatedValve.model,
            location: updatedValve.location,
            unitId: updatedValve.unit.id,
            hardwareId: updatedValve.hardwareId,
            buildingId: updatedValve.buildingId,
            inUse: updatedValve.inUse,
            projectId: get(updatedValve, ['activeProject', 'id'], '')
          })
          setAlertState({
            open: true,
            severity: 'success',
            alertMessage: 'Changes saved.'
          })
        }
      })
    } else {
      createValve({
        variables: {
          input
        },
        onCompleted: (data) => {
          const newValve = data.createValve.valve
          if (newValve) {
            setAlertState({
              open: true,
              severity: 'success',
              alertMessage: 'New valve added successfully.'
            })
            setTimeout(() => {
              window.location.href = `/buildings/${buildingId}/valves/${newValve.id}`
            }, alertState.shownFor)
          } else {
            // We have errors
            setAlertState({
              open: true,
              severity: 'error',
              alertMessage: data.createValve.error
            })
          }
        }
      })
    }
  }

  const [unlinkSensor] = useUnlinkValveSensorMutation()
  const onClickUnlink = (sensor) => {
    unlinkSensor({
      variables: {
        input: {
          id: valve.id,
          sensorId: sensor.id
        }
      },
      refetchQueries: ['FetchValveUnlinkedSensors', 'FetchSensorsTable']
    })
  }

  return {
    // isSuperadmin,
    // isPropertyAdmin,
    control,
    handleSubmit,
    errors,
    onSubmit,
    projects,
    units,
    onClickUnlink
  }
}

export default useCreateEditValve

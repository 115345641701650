import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import {
  Button,
  TextField,
  Box,
  Typography,
  MenuItem,
  Grid,
  Paper,
  Snackbar,
  Alert,
  Stack
} from '@mui/material'
import useCreateEditValve from './useCreateEditValve'
import SensorsTable from '../../organisms/SensorsTable'
import AddLinkIcon from '@mui/icons-material/AddLink'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import WarningIcon from '@mui/icons-material/Warning'
import { NUMBER_OF_ROWS_PER_PAGE_FIVE } from '../../../common/constants/pagination'
import { useNavigate } from 'react-router-dom'
import LinkSensorDialog from './LinkSensorDialog'

const ValveForm = ({ building, valve }) => {
  const navigate = useNavigate()

  const [sensorDialogOpen, setSensorDialogOpen] = useState(false)

  const [alertState, setAlertState] = useState({
    open: false,
    severity: 'success',
    alertMessage: '',
    shownFor: 2500 // time alert is shown in ms
  })

  const { handleSubmit, control, errors, onSubmit, projects, units, onClickUnlink } = useCreateEditValve({
    valve,
    building,
    context: valve ? 'edit' : 'create',
    alertState,
    setAlertState
  })

  const valveId = valve ? valve.id : -1
  return (
    <Paper sx={{ paddingLeft: 4, paddingRight: 4, paddingBottom: 2, marginTop: 1 }}>
      <Grid container spacing={3} justifyContent='center' sx={{ overflow: 'hidden' }}>
        <Grid item xs={12} md={4}>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmit, (errors, e) => {
              if (errors.length) console.log(errors)
            })}
            noValidate
            sx={{ pt: 2, textAlign: 'center' }}
          >
            <Typography variant={'h5'} sx={{ mb: 2 }}>
              Valve Details
            </Typography>
            <Controller
              name='make'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Make'
                  type='text'
                  fullWidth
                  error={!!errors.make}
                  helperText={errors.make?.message}
                  disabled={valveId !== -1}
                />
              )}
            />
            <Controller
              name='model'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Model'
                  type='text'
                  fullWidth
                  error={!!errors.model}
                  helperText={errors.model?.message}
                  disabled={valveId !== -1}
                />
              )}
            />
            <Controller
              name='location'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Location'
                  type='text'
                  fullWidth
                  error={!!errors.location}
                  helperText={errors.location?.message}
                />
              )}
            />
            <Controller
              name='unitId'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={building.buildingType === 'commercial' ? 'Zone' : 'Unit'}
                  margin='dense'
                  sx={{
                    textAlign: 'left',
                    '& .MuiSelect-select': {
                      alignItems: 'left'
                    }
                  }}
                  fullWidth
                  select
                  SelectProps={{ MenuProps: { style: { maxHeight: '40%' }, disableScrollLock: true } }}
                  error={!!errors.unitId}
                  helperText={errors.unitId?.message}
                >
                  {units.map((unit) => (
                    <MenuItem key={unit.id} value={unit.id}>
                      {unit.number}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name='hardwareId'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Hardware ID'
                  type='text'
                  fullWidth
                  error={!!errors.hardwareId}
                  helperText={errors.hardwareId?.message}
                  disabled={valveId !== -1}
                  autoComplete='off'
                />
              )}
            />
            <Controller
              name='projectId'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Project'
                  sx={{
                    mb: 3,
                    textAlign: 'left',
                    '& .MuiSelect-select': {
                      alignItems: 'left'
                    }
                  }}
                  SelectProps={{ MenuProps: { style: { maxHeight: '40%' }, disableScrollLock: true } }}
                  fullWidth
                  select
                  error={!!errors.projectId}
                  helperText={errors.projectId?.message}
                >
                  <MenuItem key='' value=''>
                    None
                  </MenuItem>
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Button sx={{ mr: 2 }} variant='contained' type='submit'>
              {valve ? 'Save' : 'Add Valve'}
            </Button>
            <Button variant='outlined' onClick={() => navigate('..', { relative: 'path' })}>
              {/** Go to valves list */}
              Cancel
            </Button>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              open={alertState.open}
              autoHideDuration={alertState.shownFor ? alertState.shownFor : 2500}
              onClose={() => setAlertState({ ...alertState, open: false })}
            >
              <Alert
                onClose={() => setAlertState({ ...alertState, open: false })}
                severity={alertState.severity}
                sx={{ width: '100%' }}
                variant='filled'
              >
                {alertState.alertMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} sx={{ minWidth: '350px' }}>
          <Box sx={{ pt: 2, textAlign: 'center' }}>
            <Grid container>
              <Grid item xs={3} sx={{ pl: '2em', pt: '0.25em' }}>
                {valveId !== -1 && (
                  <Stack direction='row'>
                    <Typography>Valve State: {valve.isOpen ? 'Open' : 'Closed'}</Typography>
                    {valve.isOpen ? (
                      <RadioButtonCheckedIcon sx={{ color: 'green', ml: '3%' }}></RadioButtonCheckedIcon>
                    ) : (
                      <WarningIcon sx={{ color: 'red' }}></WarningIcon>
                    )}
                  </Stack>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'h5'} sx={{ mb: 2 }}>
                  Associated Water Sensors
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {valveId !== -1 && (
                  <>
                    <Button
                      startIcon={<AddLinkIcon />}
                      variant='contained'
                      className='float-right'
                      onClick={() => setSensorDialogOpen(true)}
                    >
                      Link&nbsp;Sensors
                    </Button>
                    <LinkSensorDialog
                      open={sensorDialogOpen}
                      setOpen={setSensorDialogOpen}
                      valveId={valveId}
                      buildingId={valveId === -1 ? building.id : valve.buildingId}
                      columnDisplay={true}
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Paper sx={{ p: 2 }}>
              {valveId === -1 ? (
                <Typography>Create a valve first to link water sensors.</Typography>
              ) : (
                <SensorsTable
                  building={building}
                  valveId={valveId}
                  displayColumns={[
                    'deviceName',
                    'location',
                    'unit',
                    'lastSeen',
                    'battery',
                    'hardwareId',
                    'optionsMenu'
                  ]}
                  menuOptions={{
                    viewSensor: null,
                    unlinkSensor: {
                      onClick: onClickUnlink,
                      onClickArgs: [],
                      optionText: 'Unlink Sensor'
                    }
                  }}
                  emptyMessage='No Sensors Linked'
                  numRowsPerPage={NUMBER_OF_ROWS_PER_PAGE_FIVE}
                />
              )}
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default ValveForm

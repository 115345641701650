import React, { useState } from 'react'

import {
  Slider,
  Typography,
  Box,
  Button,
  TextField,
  Tooltip,
  SliderValueLabelProps,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText
} from '@mui/material'

import { ALL_SEVERITIES, SEVERITY_LABELS } from '../../../../common/constants/alerts'

interface Props {
  initialSeverity: string
  acknowledgedUserName: any
  feedback: string
  onSubmit: (severity: string, feedback: string) => void
}

const marks = ALL_SEVERITIES.map((severity, index) => ({
  value: index,
  label: severity.charAt(0).toUpperCase() + severity.substr(1)
}))

const SeveritySelector = ({ acknowledgedUserName, initialSeverity, feedback, onSubmit }: Props) => {
  const [severityIndex, setSeverityIndex] = useState(
    initialSeverity ? ALL_SEVERITIES.indexOf(initialSeverity) : null
  )

  const [error, setError] = useState(false)

  const handleSeverityChange = (event) => {
    setError(false)
    setSeverityIndex(event.target.value)
  }

  const [userFeedback, setUserFeedback] = useState(feedback ? feedback : '')

  const handleSubmit = () => {
    if (severityIndex === null) {
      setError(true)
      return
    }
    const selectedSeverity = ALL_SEVERITIES[severityIndex]
    onSubmit(selectedSeverity, userFeedback)
  }

  const severitySet = acknowledgedUserName !== null && initialSeverity !== null

  const ValueLabelComponent = (props: SliderValueLabelProps) => {
    const { children, value } = props
    return (
      <Tooltip enterTouchDelay={0} placement='top' title={SEVERITY_LABELS[value]}>
        {children}
      </Tooltip>
    )
  }

  return (
    <>
      <Box
        className='set-severity'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Center align items on the cross-axis (horizontally for column flex-direction)
          justifyContent: 'center' // This will also center the content, but vertically. It's not strictly needed here unless you want vertical centering inside the box.
        }}
      >
        <FormControl disabled={acknowledgedUserName !== null && initialSeverity !== null} error={error}>
          <FormLabel id='severity-radio-label'>
            <Typography id='severity-slider' align='center'>
              {!severitySet && (
                <Typography variant='h6' sx={{ paddingBottom: 2.5, marginTop: -1 }}>
                  Let us know what happened
                </Typography>
              )}
              {acknowledgedUserName === null ? 'Select Severity' : 'Severity'} Level*
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby='severity-radio-label'
            value={severityIndex}
            onChange={handleSeverityChange}
            row
          >
            {marks.map(({ value, label }) => (
              <FormControlLabel value={value} control={<Radio />} label={label} />
            ))}
          </RadioGroup>
          {error && <FormHelperText>You must select a Severity Level</FormHelperText>}
        </FormControl>
        <br />
        <TextField
          id='user-feedback'
          label={severitySet ? 'Feedback' : 'Enter Feedback'}
          multiline
          rows={4}
          variant='outlined'
          fullWidth
          value={userFeedback}
          disabled={severitySet}
          sx={{
            '& .Mui-disabled': {
              color: '#365970',
              backgroundColor: '#fafafc'
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#365970'
            }
          }}
          onChange={(event) => setUserFeedback(event.target.value)}
          inputProps={{ maxLength: 500, readOnly: severitySet }}
          hidden={severitySet && userFeedback === ''}
        />
        <br />
        {!severitySet ? (
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Send Feedback
          </Button>
        ) : (
          <Box className='ack-header' sx={{ fontSize: 18, marginTop: -1 }}>
            Set by: {acknowledgedUserName?.firstName + ' ' + acknowledgedUserName?.lastName}
          </Box>
        )}
      </Box>
      <br />
    </>
  )
}

export default SeveritySelector

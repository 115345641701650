import { find, omit } from 'lodash'
import {
  REPORT_DISPLAY_TYPES,
  REPORT_DISPLAY_TYPE_NAMES,
  ALERT_REPORT_DISPLAY,
  TEMPERATURE_REPORT_DISPLAY,
  OCCUPANCY_REPORT_DISPLAY,
  IAQ_REPORT_DISPLAY,
  POWER_REPORT_DISPLAY,
  SOUND_REPORT_DISPLAY,
  OCCUPANT_FLOW_DISPLAY,
  WEEK
} from '../constants/reports'
import { addDays, subDays, subMonths } from 'date-fns'

export const getReportUrl = (token: string): string => `${process.env.BASE_URI}/reports/${token}`

export const getMetricsList = (criteria) => {
  const metricList = []
  REPORT_DISPLAY_TYPES.forEach((disp) => {
    if (criteria[disp]) metricList.push(find(REPORT_DISPLAY_TYPE_NAMES, ['key', disp]).value)
  })
  return metricList
}

export const fetchCriteria = (filterCriteria) =>
  omit(filterCriteria, [
    ALERT_REPORT_DISPLAY,
    TEMPERATURE_REPORT_DISPLAY,
    OCCUPANCY_REPORT_DISPLAY,
    IAQ_REPORT_DISPLAY,
    POWER_REPORT_DISPLAY,
    SOUND_REPORT_DISPLAY,
    OCCUPANT_FLOW_DISPLAY,
    'reportingStartDate',
    'reportInterval',
    'name',
    'recipients',
    'drilldown',
    'lowHigh'
  ])

export const today = new Date(new Date().setHours(0, 0, 0, 0))
export const tomorrow = addDays(today, 1)

export const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1)

export const oneMonthAgo = subMonths(today, 1)

export const initialFiltersState = {
  buildingIds: [],
  unitIds: [],
  sensorIds: [],
  dateFilterType: 'date_range',
  startDate: subDays(today, 30),
  endDate: today,
  alertsDisplay: false,
  temperatureDisplay: false,
  occupancyDisplay: false,
  iaqDisplay: false,
  powerDisplay: false,
  soundDisplay: false,
  occupantFlowDisplay: false,
  reportingStartDate: tomorrow,
  reportInterval: WEEK,
  name: 'My Report',
  recipients: []
}

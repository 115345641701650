export const TYPE_OCCUPANT = 'Occupant'
export const TYPE_SUPER_ADMIN = 'Superadmin'
export const TYPE_PROPERTY_ADMIN = 'PropertyAdmin'

export const ALERT_REPORT_DISPLAY = 'alertsDisplay'
export const TEMPERATURE_REPORT_DISPLAY = 'temperatureDisplay'
export const OCCUPANCY_REPORT_DISPLAY = 'occupancyDisplay'
export const IAQ_REPORT_DISPLAY = 'iaqDisplay'
export const POWER_REPORT_DISPLAY = 'powerDisplay'
export const SOUND_REPORT_DISPLAY = 'soundDisplay'
export const OCCUPANT_FLOW_DISPLAY = 'occupantFlowDisplay'

export const REPORT_DISPLAY_TYPES = [
  ALERT_REPORT_DISPLAY,
  TEMPERATURE_REPORT_DISPLAY,
  OCCUPANCY_REPORT_DISPLAY,
  IAQ_REPORT_DISPLAY,
  POWER_REPORT_DISPLAY,
  SOUND_REPORT_DISPLAY,
  OCCUPANT_FLOW_DISPLAY
]

export const REPORT_SAVE_OMIT_ATTRIBUTES = [
  ...REPORT_DISPLAY_TYPES,
  'reportingStartDate',
  'reportInterval',
  'name'
]

export const DAY = 'day'
export const WEEK = 'week'
export const MONTH = 'month'
export const QUARTER = 'quarter'
export const YEAR = 'year'

export const REPORT_INTERVAL_SELECT = [
  { key: DAY, value: 'Daily' },
  { key: WEEK, value: 'Weekly' },
  { key: MONTH, value: 'Monthly' },
  { key: QUARTER, value: 'Quarterly' },
  { key: YEAR, value: 'Yearly' }
]

export const REPORT_DISPLAY_TYPE_NAMES = [
  { key: ALERT_REPORT_DISPLAY, value: 'Alerts' },
  { key: TEMPERATURE_REPORT_DISPLAY, value: 'Temperature' },
  { key: OCCUPANCY_REPORT_DISPLAY, value: 'Occupancy' },
  { key: IAQ_REPORT_DISPLAY, value: 'IAQ' },
  { key: POWER_REPORT_DISPLAY, value: 'Power' },
  { key: SOUND_REPORT_DISPLAY, value: 'Sound' },
  { key: OCCUPANT_FLOW_DISPLAY, value: 'Occupant Flow' }
]

export const TEMPERATURE_LABEL = 'Temperature (°C)'
export const HUMIDITY_LABEL = 'Humidity %'
export const SOUND_LABEL = 'Sound Level (dB)'
export const CO2_LABEL = 'CO₂ (PPM)'
export const CO_LABEL = 'CO (PPM)'
export const TVOC_LABEL = 'TVOCs (mg/m³)'
export const PRESSURE_LABEL = 'Pressure (hPa)'
export const PM_2_5_LABEL = 'PM2.5 (µg/m³)'
export const PM_10_LABEL = 'PM10 (µg/m³)'
export const LIGHT_LEVEL_LABEL = 'Light Level'

export const ACTIVE_ENERGY_LABEL = 'Power Usage (kWh)'

export const SOUND_LEVEL_LABEL = 'Sound Level (dBA)'

// export const OCCUPANT_FLOW_LABEL = 'Occupant Flow'

export const OUTLIER_FILTER_TYPES = [
  {
    displayName: 'Low Temperature',
    iaqAttribute: 'temperature',
    lowHigh: 'low',
    tableDisplay: TEMPERATURE_LABEL
  },
  {
    displayName: 'High Temperature',
    iaqAttribute: 'temperature',
    lowHigh: 'high',
    tableDisplay: TEMPERATURE_LABEL
  },
  { displayName: 'CO₂', iaqAttribute: 'co2', lowHigh: 'high', tableDisplay: CO2_LABEL },
  { displayName: 'CO', iaqAttribute: 'co', lowHigh: 'high', tableDisplay: CO_LABEL },
  { displayName: 'TVOCs', iaqAttribute: 'tvoc_mg_m3', lowHigh: 'high', tableDisplay: TVOC_LABEL },
  { displayName: 'PM2.5', iaqAttribute: 'pm_2_5', lowHigh: 'high', tableDisplay: PM_2_5_LABEL },
  { displayName: 'PM10', iaqAttribute: 'pm_10', lowHigh: 'high', tableDisplay: PM_10_LABEL },
  { displayName: 'Low Humidity', iaqAttribute: 'humidity', lowHigh: 'low', tableDisplay: HUMIDITY_LABEL },
  { displayName: 'High Humidity', iaqAttribute: 'humidity', lowHigh: 'high', tableDisplay: HUMIDITY_LABEL },
  { displayName: 'Low Pressure', iaqAttribute: 'pressure', lowHigh: 'low', tableDisplay: PRESSURE_LABEL },
  { displayName: 'High Pressure', iaqAttribute: 'pressure', lowHigh: 'high', tableDisplay: PRESSURE_LABEL }
]

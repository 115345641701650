import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAlertsTableQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>;
  eventFilter?: Types.InputMaybe<Types.Scalars['String']>;
  severityFilter?: Types.InputMaybe<Types.Scalars['String']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
  buildingId?: Types.InputMaybe<Types.Scalars['ID']>;
  sensorId?: Types.InputMaybe<Types.Scalars['ID']>;
  reportFilters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchAlertsTableQuery = { __typename?: 'Query', fetchAlerts: { __typename?: 'AlertCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number }, collection: Array<{ __typename?: 'Alert', alertType: Types.AlertType, severity?: Types.Severity | null, feedback?: string | null, createdAt: unknown, acknowledgedUser?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null, sensorEvent: { __typename?: 'SensorEvent', temperature?: number | null, humidity?: number | null, splMax?: number | null, sensor: { __typename?: 'Sensor', name?: string | null, deviceName?: string | null, location?: string | null, unit?: { __typename?: 'Unit', number?: string | null } | null, building?: { __typename?: 'Building', name?: string | null } | null } }, userAlerts: Array<{ __typename?: 'UserAlert', sentAt: unknown, acknowledgedAt?: unknown | null, sentSms: boolean, sentEmail: boolean, sentMobile: boolean, notSentOutsideHours: boolean, user: { __typename?: 'User', firstName?: string | null, lastName?: string | null } }> }> } };


export const FetchAlertsTableDocument = gql`
    query FetchAlertsTable($query: String, $eventFilter: String, $severityFilter: String, $pagination: PaginationType, $buildingId: ID, $sensorId: ID, $reportFilters: WidgetFiltersType, $reportToken: String) {
  fetchAlerts(
    query: $query
    eventFilter: $eventFilter
    severityFilter: $severityFilter
    pagination: $pagination
    buildingId: $buildingId
    sensorId: $sensorId
    reportFilters: $reportFilters
    reportToken: $reportToken
  ) {
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    collection {
      alertType
      acknowledgedUser {
        firstName
        lastName
      }
      severity
      feedback
      createdAt
      sensorEvent {
        temperature
        humidity
        splMax
        sensor {
          name
          deviceName
          location
          unit {
            number
          }
          building {
            name
          }
        }
      }
      userAlerts {
        sentAt
        acknowledgedAt
        sentSms
        sentEmail
        sentMobile
        notSentOutsideHours
        user {
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useFetchAlertsTableQuery__
 *
 * To run a query within a React component, call `useFetchAlertsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAlertsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAlertsTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      eventFilter: // value for 'eventFilter'
 *      severityFilter: // value for 'severityFilter'
 *      pagination: // value for 'pagination'
 *      buildingId: // value for 'buildingId'
 *      sensorId: // value for 'sensorId'
 *      reportFilters: // value for 'reportFilters'
 *      reportToken: // value for 'reportToken'
 *   },
 * });
 */
export function useFetchAlertsTableQuery(baseOptions?: Apollo.QueryHookOptions<FetchAlertsTableQuery, FetchAlertsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAlertsTableQuery, FetchAlertsTableQueryVariables>(FetchAlertsTableDocument, options);
      }
export function useFetchAlertsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAlertsTableQuery, FetchAlertsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAlertsTableQuery, FetchAlertsTableQueryVariables>(FetchAlertsTableDocument, options);
        }
export type FetchAlertsTableQueryHookResult = ReturnType<typeof useFetchAlertsTableQuery>;
export type FetchAlertsTableLazyQueryHookResult = ReturnType<typeof useFetchAlertsTableLazyQuery>;
export type FetchAlertsTableQueryResult = Apollo.QueryResult<FetchAlertsTableQuery, FetchAlertsTableQueryVariables>;
import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, MenuItem, Select, Typography } from '@mui/material'
import { HEATMAP_STYLING } from '../../../common/constants/maps'
import { useGetOccupancyFlowDataQuery } from './GraphQl/gql.generated'
import { isEmpty, isNil } from 'lodash'

const OccupancyFlowDashboard = () => {
  const [timeRange, setTimeRange] = useState(60) // Default value: 60
  const mapRef = useRef(null) // Reference to the map instance
  const markersRef = useRef([]) // Track markers currently on the map
  const heatmapRef = useRef(null)
  const markersVisible = useRef(true)
  const [mapInitialized, setMapInitialized] = useState(false)
  const options = [
    { label: 'Last hour', value: 60 },
    { label: 'Last 2 hours', value: 120 },
    { label: 'Last 3 hours', value: 180 },
    { label: 'Last 6 hours', value: 360 },
    { label: 'Last 12 hours', value: 720 },
    { label: 'Last day', value: 1440 },
    { label: 'Last 2 days', value: 2880 },
    { label: 'Last 3 days', value: 4320 },
    { label: 'Last week', value: 10080 },
    { label: 'Last 2 weeks', value: 20160 },
    { label: 'Last 3 weeks', value: 30240 },
    { label: 'Last month', value: 43800 },
    { label: 'Last 2 months', value: 87600 },
    { label: 'Last 3 months', value: 131400 },
    { label: 'Last 6 months', value: 262800 },
    { label: 'Last year', value: 525601 }
  ]
  const handleChange = (event) => {
    setTimeRange(event.target.value) // Update selected value
  }

  const { data, loading, refetch } = useGetOccupancyFlowDataQuery({
    variables: { timeRange }
  })

  // Sets the map on all markers in the array.
  function setMapOnAll(map) {
    for (let i = 0; i < markersRef.current.length; i++) {
      markersRef.current[i].setMap(map)
    }
  }

  // Removes the markers from the map, but keeps them in the array.
  function hideMarkers() {
    setMapOnAll(null)
  }

  // Shows any markers currently in the array.
  function showMarkers() {
    setMapOnAll(mapRef.current)
  }

  const toggleMarkers = () => {
    if (markersVisible.current) {
      setMapOnAll(null)
      markersVisible.current = false
    } else {
      setMapOnAll(mapRef.current)
      markersVisible.current = true
    }
  }
  useEffect(() => {
    const loadGoogleMapsApi = () => {
      const script = document.createElement('script')
      script.id = 'google-maps'
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=visualization`
      script.async = true
      script.defer = true
      document.body.appendChild(script)
      script.onload = () => initMap()
    }

    const initMap = () => {
      mapRef.current = new google.maps.Map(document.getElementById('map'), {
        zoom: 18,
        mapTypeControl: false,
        collisionBehavior: google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY
      })

      const styledMapOptions = { name: 'Silver Map' }
      mapRef.current.mapTypes.set(
        'silvermap',
        new google.maps.StyledMapType(HEATMAP_STYLING, styledMapOptions)
      )
      mapRef.current.setMapTypeId('silvermap')
      heatmapRef.current = new google.maps.visualization.HeatmapLayer({
        data: [],
        map: mapRef.current,
        radius: 70
        // gradient: ['rgba(255, 255, 255, 0)',
        //   '#bdc4c9',
        //   '#949ea2',
        //   '#5E7E89ff',
        //   '#496D78ff',
        //   '#355D68ff',
        //   '#183f48']
      })

      setMapInitialized(true)
    }

    loadGoogleMapsApi()
  }, [])

  const clearMarkers = () => {
    markersRef.current.forEach((marker) => marker.setMap(null)) // Remove from map
    markersRef.current = [] // Clear marker array
  }
  useEffect(() => {
    if (data && mapInitialized) {
      // Clear existing markers and add new ones
      clearMarkers()
      if (!isEmpty(data.getOccupancyFlow)) {
        const flowData = []
        data.getOccupancyFlow.forEach((sensor) => {
          if (sensor.count_data)
            flowData.push({
              location: new google.maps.LatLng(sensor.sensor_latitude, sensor.sensor_longitude),
              weight: sensor.count_data.direction_in_total + sensor.count_data.direction_out_total
            })
        })
        heatmapRef.current.setData(flowData)

        // Function to add new markers
        const addMarkers = () => {
          // Create a custom rounded text box marker
          class RoundedTextBox extends google.maps.OverlayView {
            constructor(position, text) {
              super()
              this.position = position
              this.text = text
              this.div = null
            }

            onAdd() {
              this.div = document.createElement('div')
              this.div.className = 'text-marker'
              this.div.innerHTML = this.text

              const panes = this.getPanes()
              panes.overlayMouseTarget.appendChild(this.div)
            }

            draw() {
              const overlayProjection = this.getProjection()
              const position = overlayProjection.fromLatLngToDivPixel(this.position)

              if (this.div) {
                this.div.style.left = `${position.x}px`
                this.div.style.top = `${position.y}px`
              }
            }

            onRemove() {
              if (this.div) {
                this.div.parentNode.removeChild(this.div)
                this.div = null
              }
            }
          }

          if (mapRef.current) {
            const bounds = new google.maps.LatLngBounds()
            // Add markers with rounded text boxes
            markersRef.current = data.getOccupancyFlow.map((sensor) => {
              let titleDisplay = sensor.location_name
              if (
                !isNil(sensor.count_data) &&
                !isNil(sensor.count_data.direction_in_total) &&
                !isNil(sensor.count_data.direction_out_total)
              ) {
                titleDisplay +=
                  '<br>' +
                  'In: ' +
                  sensor.count_data.direction_in_total.toLocaleString() +
                  '  Out: ' +
                  sensor.count_data.direction_out_total.toLocaleString()
              }

              const marker = new RoundedTextBox(
                new google.maps.LatLng({
                  lat: parseFloat(sensor.sensor_latitude),
                  lng: parseFloat(sensor.sensor_longitude)
                }),
                titleDisplay
              )
              marker.setMap(mapRef.current)
              bounds.extend({
                lat: parseFloat(sensor.sensor_latitude),
                lng: parseFloat(sensor.sensor_longitude)
              })
              return marker
            })
            mapRef.current.fitBounds(bounds)
          }
        }

        addMarkers()
      } else {
        heatmapRef.current.setData([])
      }
    }
  }, [data, mapInitialized])

  useEffect(() => {
    refetch()
  }, [timeRange])

  return (
    <>
      <Box display='flex' gap={2} mb='1em'>
        <Typography variant='h6'>Occupancy Flow</Typography>
        <Select value={timeRange} onChange={handleChange} sx={{ minWidth: 320 }} size='small' displayEmpty>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <Button variant='contained' onClick={toggleMarkers} size='small'>
          Show/Hide Labels
        </Button>
      </Box>
      <div id='map' style={{ width: '99%', minHeight: '500px' }}></div>
    </>
  )
}

export default OccupancyFlowDashboard

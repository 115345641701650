import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import {
  Button,
  TextField,
  Box,
  Typography,
  MenuItem,
  Grid,
  Stack,
  Alert,
  Paper,
  CircularProgress
} from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import SquareIcon from '@mui/icons-material/Square'
import useZoneCreateEditPage from './useZoneCreateEditPage'
import { useParams } from 'react-router-dom'
import FloorGrid from '../../organisms/FloorGrid'
import { toInteger } from 'lodash'
import useFloorLayout from './useFloorLayout'
import { DISPLAY_ZONE_TYPE } from '../../../common/constants/units'
import { idxToColor } from '../../../common/utils/floorLayouts'
import { useGetCurrentUserTypeQuery } from '../PropertyManagersPage/GraphQl/gql.generated'
import { TYPE_SUPER_ADMIN } from '../../../common/constants/users'
import { get } from 'lodash'

const ZoneCreateEditPage = () => {
  const { unitId } = useParams('unitId')
  const { buildingId } = useParams('buildingId')

  const [floor, setFloor] = useState(1)
  const [floorUnits, setFloorUnits] = useState({})

  const [floorCellsLoaded, setFloorCellsLoaded] = useState(false)

  const [currentUnitIdx, setCurrentUnitIdx] = useState(-1)
  const { grid } = useFloorLayout({
    buildingId,
    unitId,
    floor,
    setFloorUnits,
    setFloorCellsLoaded,
    currentUnitIdx,
    setCurrentUnitIdx
  })

  // Get current user type
  const { data: userData } = useGetCurrentUserTypeQuery()
  const currentUserType = get(userData, ['getCurrentUser', 'type'], '')

  const [alertState, setAlertState] = useState({
    open: false,
    severity: 'success',
    alertMessage: '',
    shownFor: 2500 // time alert is shown in ms
  })

  const { handleSubmit, control, errors, onSubmit, watch, localUnit, zoneTypes, building } =
    useZoneCreateEditPage({
      context: unitId ? 'edit' : 'create',
      unitId,
      buildingId,
      setFloor,
      grid,
      alertState,
      setAlertState
    })

  useEffect(() => {
    watch((data, { name: field, type }) => {
      if (field === 'floor' && type === 'change') {
        if (data[field]) setFloor(toInteger(data[field]))
      }
    })
  }, [])

  return (
    <Paper sx={{ paddingBottom: '1em' }}>
      <Grid container spacing={1} justifyContent='center'>
        <Grid item xs={12} md={4} sx={{ margin: '1em' }}>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ m: 'auto', pt: 2, textAlign: 'center', minWidth: '350px', maxWidth: '500px' }}
          >
            <Typography variant={'h5'} sx={{ mb: 2 }}>
              Zone Details
            </Typography>
            <Controller
              name='number'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label={building.buildingType === 'commercial' ? 'Zone Name' : 'Unit Number'}
                  type='text'
                  fullWidth
                  error={!!errors.number}
                  helperText={errors.number?.message}
                />
              )}
            />
            <Controller
              name='zoneType'
              control={control}
              render={({ field }) => (
                <TextField
                  className='zoneType-dropdown'
                  {...field}
                  label={'Zone Type'}
                  margin='dense'
                  sx={{
                    textAlign: 'left',
                    '& .MuiSelect-select': {
                      alignItems: 'left'
                    }
                  }}
                  fullWidth
                  select
                  SelectProps={{
                    MenuProps: {
                      classes: { paper: 'zoneType-items' },
                      style: { maxHeight: '40%' },
                      disableScrollLock: true
                    }
                  }}
                  error={!!errors.zoneType}
                  helperText={errors.zoneType?.message}
                >
                  <MenuItem key='' value=''>
                    None
                  </MenuItem>
                  {zoneTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {DISPLAY_ZONE_TYPE[type]}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name='alertEscalationMinutes'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Minutes Between Alert Escalation'
                  inputProps={{ type: 'number' }}
                  fullWidth
                  error={!!errors.alertEscalationMinutes}
                  helperText={errors.alertEscalationMinutes?.message}
                />
              )}
            />
            <Controller
              name='floor'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Floor Number'
                  inputProps={{ type: 'number' }}
                  fullWidth
                  error={!!errors.floor}
                  helperText={errors.floor?.message}
                />
              )}
            />
            <Button sx={{ mr: 2 }} variant='contained' type='submit'>
              Save
            </Button>
            <Button
              variant='outlined'
              onClick={() => {
                window.location.href = `/units?building_id=${buildingId}`
              }}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={5} sx={{ margin: '1em' }}>
          {currentUserType === TYPE_SUPER_ADMIN && (
            <Stack
              direction='column'
              spacing={2}
              sx={{ textAlign: 'center', justifyContent: 'center', maxWidth: '100%', maxHeight: '100%' }}
            >
              <Typography variant='h6'>Floor Layout</Typography>
              {floorCellsLoaded && (
                <>
                  <FloorGrid
                    grid={grid}
                    currentFloor={floor}
                    floorUnits={floorUnits}
                    currentUnit={localUnit}
                  />
                  <Grid container columnSpacing={1} rowGap={1} sx={{ justifyContent: 'center' }}>
                    {Object.keys(floorUnits).map((unitId) => (
                      <Grid item xs={2} key={unitId} sx={{ margin: '0.25em' }}>
                        <SquareIcon sx={{ color: idxToColor(floorUnits[unitId].idx) }} />
                        <Typography>{floorUnits[unitId].unit.number}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
              {!floorCellsLoaded && (
                <div style={{ display: 'block' }}>
                  <CircularProgress sx={{ marginTop: '5em' }} />
                </div>
              )}
            </Stack>
          )}
        </Grid>
        <Grid item xs={0} md={1} />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={alertState.open}
        autoHideDuration={alertState.shownFor ? alertState.shownFor : 2500}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {alertState.alertMessage}
        </Alert>
      </Snackbar>
    </Paper>
  )
}

export default ZoneCreateEditPage

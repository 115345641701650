import React from 'react'
import { Routes, Route } from 'react-router-dom'

// pages
import OccupantDashboard from './components/pages/OccupantDashboard'
import OccupancyFlowDashboard from './components/pages/OccupancyFlowDashboard'
import AlertsPage from './components/pages/AlertsPage'
import DashboardsList from './components/pages/dashboards/DashboardsList'
import EditDashboard from './components/pages/dashboards/EditDashboard'
import PublicDashboard from './components/pages/dashboards/PublicDashboard'
import SensorPage from './components/pages/SensorPage'
import SensorCreatePage from './components/pages/SensorCreatePage'
import GatewaysPage from './components/pages/GatewaysPage'
import PublicAlert from './components/pages/PublicAlert'
import UserReportsPage from './components/pages/UserReportsPage'
import ReportPage from './components/pages/ReportPage'
import PropertyManagersPage from './components/pages/PropertyManagersPage'
import ValvesPage from './components/pages/Valves/ValveList'
import CreateValvePage from './components/pages/Valves/CreateValve'
import ZoneCreateEditPage from './components/pages/ZoneCreateEditPage'

const Router = () => {
  return (
    <Routes>
      <Route path='/dashboard' element={<OccupantDashboard />} />
      <Route path='/alerts' element={<AlertsPage />} />
      <Route path='/alerts/:shortcode' element={<PublicAlert />} />
      <Route path='/dashboards' element={<DashboardsList />} />
      <Route path='/dashboards/:token' element={<PublicDashboard />} />
      <Route path='/dashboards/:token/edit' element={<EditDashboard />} />
      <Route path='/dashboard/occupancy_flow' element={<OccupancyFlowDashboard />} />
      <Route path='/sensors/:id' element={<SensorPage />} />
      <Route path='/sensors/new' element={<SensorCreatePage />} />
      <Route path='/gateways' element={<GatewaysPage />} />
      <Route path='/user_reports' element={<UserReportsPage />} />
      <Route path='/reports/:token' element={<ReportPage />} />
      <Route path='property_managers' element={<PropertyManagersPage />} />

      {/* Valves */}
      <Route path='/buildings/:buildingId/valves' element={<ValvesPage />} />
      <Route path='/buildings/:buildingId/valves/new' element={<CreateValvePage />} />
      <Route path='/buildings/:buildingId/valves/:valveId' element={<CreateValvePage />} />

      {/* Zone Creation/Editing */}
      <Route path='/buildings/:buildingId/units/new' element={<ZoneCreateEditPage context='create' />} />
      <Route
        path='/buildings/:buildingId/units/:unitId/edit'
        element={<ZoneCreateEditPage context='edit' />}
      />
    </Routes>
  )
}

export default Router

import { colors } from '../hooks/useRandomColour'

export const idxToColor = (idx) => {
  // id = parseInt(idx)
  // const r = (id * 9311 + 199) % 256
  // const g = (id * 40503 + 7937) % 256
  // const b = (id * 127 + 311) % 256
  // let color = '#'
  // for (let c of [r, g, b]) {
  //   const hex_c = c.toString(16)
  //   color += hex_c.length == 2 ? hex_c : '0' + hex_c
  // }
  // return color
  return colors[idx]
}

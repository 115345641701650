import React, { Fragment, useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useFetchAlertDataQuery } from '../GraphQl/gql.generated'

import EventTypeFilter from '../../../atoms/EventTypeFilter'
import AlertSeverityFilter from '../../../atoms/AlertSeverityFilter'
import { StyledTableCell } from '../../../../common/theme/components/tables/StyledTableCell'
import { Bar } from 'react-chartjs-2'
import { capitalize, isEmpty, isUndefined, round } from 'lodash'
import MouseOverPopover from '../../../molecules/MouseOverPopover'

const AlertsSummaryCard = ({ filters, reportToken }) => {
  const [alertsSummary, setAlertsSummary] = useState({})
  const [eventFilter, setEventFilter] = useState(null)
  const [severityFilter, setSeverityFilter] = useState(null)

  const { data: resultData, loading } = useFetchAlertDataQuery({
    variables: { filters, eventFilter, severityFilter, reportToken }
  })

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        stepSize: 1
      },
      x: {
        stacked: true
      }
    }
  }

  const getUncategorizedPercentage = (data) => {
    const totalCount = Object.values(data).reduce((total, current) => total + current, 0)
    return isUndefined(data.uncategorized) || data.uncategorized === 0
      ? 0
      : round((data.uncategorized / totalCount) * 100, 1)
  }

  useEffect(() => {
    if (!loading) {
      setAlertsSummary(resultData.fetchAlertData)
    }
  }, [resultData])

  return (
    <Paper sx={{ mt: 2, p: 4 }}>
      <h4 align={'center'}>Alerts Summary</h4>

      <Grid container alignItems='center' spacing={3}>
        <EventTypeFilter eventFilter={eventFilter} setEventFilter={setEventFilter} />
        <AlertSeverityFilter severityFilter={severityFilter} setSeverityFilter={setSeverityFilter} />
        <Grid item xs={8} />
      </Grid>
      <TableContainer>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Alerting Sensors</StyledTableCell>
              <StyledTableCell>Alert Events</StyledTableCell>
              <StyledTableCell>Alerts Sent</StyledTableCell>
              <StyledTableCell>Alerts Acknowledged</StyledTableCell>
              <StyledTableCell>Avg. Response Time</StyledTableCell>
              <StyledTableCell>% Uncategorized</StyledTableCell>
              <StyledTableCell>Water Alerts</StyledTableCell>
              <StyledTableCell>Temperature Alerts</StyledTableCell>
              <StyledTableCell>Door Alerts</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && (
              <>
                <TableRow hover>
                  <StyledTableCell>{alertsSummary.alerting_sensors_count}</StyledTableCell>
                  <StyledTableCell>{alertsSummary.alert_events}</StyledTableCell>
                  <StyledTableCell>{alertsSummary.alerts_sent_count}</StyledTableCell>
                  <StyledTableCell>{alertsSummary.alerts_ack_count}</StyledTableCell>
                  <StyledTableCell>{alertsSummary.average_response_time}</StyledTableCell>
                  <StyledTableCell>
                    {!isEmpty(alertsSummary.alerts_categorized_count) ? (
                      <MouseOverPopover
                        baseText={getUncategorizedPercentage(alertsSummary.alerts_categorized_count)}
                      >
                        <Box sx={{ border: '2px solid #365970', padding: '10px' }}>
                          {['critical', 'high', 'medium', 'low', 'uncategorized'].map((key) =>
                            alertsSummary.alerts_categorized_count[key] ? (
                              <Typography
                                variant='body2'
                                sx={{ fontWeight: 'bold', color: '#365970', pb: 0.5, maxWidth: 500 }}
                                key={key}
                              >
                                {capitalize(key)}:&nbsp;
                                <span style={{ fontWeight: 'normal', color: '#000' }}>
                                  {alertsSummary.alerts_categorized_count[key]}
                                </span>
                              </Typography>
                            ) : null
                          )}
                        </Box>
                      </MouseOverPopover>
                    ) : (
                      'N/A'
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{alertsSummary.alerts_type_counts?.water || 0}</StyledTableCell>
                  <StyledTableCell>
                    {(alertsSummary.alerts_type_counts?.low_temperature || 0) +
                      (alertsSummary.alerts_type_counts?.high_temperature || 0)}
                  </StyledTableCell>
                  <StyledTableCell>{alertsSummary.alerts_type_counts?.door_open || 0}</StyledTableCell>
                </TableRow>
              </>
            )}
            {loading && (
              <TableRow hover>
                <TableCell align='center' colSpan={9} scope='row'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      {!loading && alertsSummary.alert_events > 0 && (
        <>
          <Bar
            data={{
              labels: alertsSummary.chart_dataset?.labels,
              datasets: alertsSummary.chart_dataset?.datasets.map((ds) => ({
                label: ds.label,
                data: Object.values(ds.data)
              }))
            }}
            options={chartOptions}
            height={'60'}
          />
        </>
      )}
    </Paper>
  )
}

export default AlertsSummaryCard

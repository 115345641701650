import React, { useEffect, useState } from 'react'
import { useFetchFloorCellsLazyQuery } from './GraphQl/gql.generated'

const useFloorLayout = ({
  buildingId,
  unitId,
  floor,
  setFloorUnits,
  setFloorCellsLoaded,
  currentUnitIdx,
  setCurrentUnitIdx
}) => {
  const building_tile_calculator = (building_sqft) => {
    return Math.min(10, Math.floor(Math.sqrt(building_sqft) / 10 / 2))
  }

  const num_rows = building_tile_calculator(200000)
  const num_columns = building_tile_calculator(200000)

  const [grid, setGrid] = useState([[]])
  const [fetchFloorCells, { data: floorCellsData, loading: floorCellsLoading }] = useFetchFloorCellsLazyQuery(
    {
      variables: {
        buildingId,
        floor
      },
      onCompleted: (data) => {
        const floorCells = data.fetchFloorCells
        const floorUnits = {}
        const newGrid = new Array(num_rows).fill(null).map(() => new Array(num_columns).fill(null))
        let unitIdx = -1
        for (let cell of floorCells) {
          if (!floorUnits[cell.unit.id]) {
            floorUnits[cell.unit.id] = { unit: cell.unit, idx: ++unitIdx }
            if (currentUnitIdx === -1 && cell.unit.id === unitId) setCurrentUnitIdx(unitIdx)
          }
          newGrid[cell.row][cell.column] = cell.unit
        }
        setGrid(newGrid)
        setFloorUnits(floorUnits)
        setFloorCellsLoaded(true)
      },
      fetchPolicy: 'network-only',
      defaultOptions: {
        notifyOnNetworkStatusChange: true
      }
    }
  )

  useEffect(() => {
    fetchFloorCells()
  }, [floor])

  return { grid, floorCellsLoading }
}

export default useFloorLayout

import React from 'react'
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import useSeverityFilter from './useSeverityFilter'

import { severities } from '../../../common/utils/alerts'

const SeverityFilter = ({
  loading,
  severityFilter,
  setSeverityFilter,
  gridSx = { minWidth: '270px', mt: '5px' }
}) => {
  const { handleSeverityChange } = useSeverityFilter({ setSeverityFilter })

  return (
    <Grid item xs={2} sx={gridSx}>
      <FormControl fullWidth>
        <InputLabel id='since-label'>Severity</InputLabel>
        <Select
          disabled={loading}
          size='small'
          labelId='severity-type-label'
          value={severityFilter || 'all'}
          label='Severity'
          onChange={handleSeverityChange}
        >
          {severities.map(({ value, title }, key) => (
            <MenuItem value={value} key={key}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}

export default SeverityFilter

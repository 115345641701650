import React, { useRef } from 'react'
import FloorCell from '../../molecules/FloorCell'
import '../../../common/theme/stylesheets/floorLayouts.css'
import { curry } from 'lodash'

const FloorGrid = ({ grid, currentFloor, floorUnits, currentUnit }) => {
  var isMouseDown = useRef(false)

  const numColumns = grid[0].length

  return (
    <table className='floorgrid'>
      <tbody
        onMouseLeave={() => (isMouseDown.current = false)}
        onMouseDown={(event) => {
          event.preventDefault()
          isMouseDown.current = true
        }}
        onMouseUp={(event) => {
          event.preventDefault()
          isMouseDown.current = false
        }}
      >
        {grid.map((_, row) => (
          <tr key={row}>
            {grid[row].map((value, col) => (
              <FloorCell
                grid={grid}
                currentFloor={currentFloor}
                currentUnit={currentUnit}
                floorUnits={floorUnits}
                key={row * numColumns + col}
                row={row}
                col={col}
                isMouseDown={isMouseDown}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default FloorGrid

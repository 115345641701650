import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  useCreateFullUnitMutation,
  useUpdateUnitMutation,
  useGetUnitLazyQuery
} from './GraphQl/gql.generated'
import { useGetBuildingQuery } from '../GatewaysPage/GraphQl/gql.generated'
import { ZoneType } from '../../../types.generated'
import { useNavigate } from 'react-router-dom'
import { useFetchUnitsLazyQuery } from '../dashboards/components/DashboardFiltersPanel/gql.generated'

const schema = yup
  .object({
    number: yup.string().required('Zone Name/Unit Number is required.'),
    floor: yup
      .number()
      .typeError('Floor Number must be a whole number.')
      .integer('Floor Number must be a whole number.')
      .required('Floor Number is required.'),
    zoneType: yup.string().nullable(),
    alertEscalationMinutes: yup
      .number()
      .typeError('Minutes Between Alert Escalation must be a whole number greater than or equal to 1.')
      .integer('Minutes Between Alert Escalation must be a whole number greater than or equal to 1')
      .min(1, 'Minutes Between Alert Escalation must be a whole number greater than or equal to 1.')
      .nullable()
  })
  .required()

const useZoneCreateEditPage = ({
  context,
  unitId,
  buildingId,
  setFloor,
  grid,
  alertState,
  setAlertState
}) => {
  const [localUnit, setLocalUnit] = useState({})

  const defaultValues = {
    number: '',
    floor: 1,
    zoneType: '',
    alertEscalationMinutes: 15
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const [getUnit, { data: unitData, loading: unitLoading }] = useFetchUnitsLazyQuery({
    variables: {
      ids: [unitId]
    },
    onCompleted: (data) => {
      const unit = data.fetchUnits[0]
      setLocalUnit(unit)
      reset({
        number: unit.number,
        floor: unit.floor,
        zoneType: unit.zoneType ? unit.zoneType : '',
        alertEscalationMinutes: unit.alertEscalationMinutes
      })
      setFloor(unit.floor)
    }
  })

  const { data: buildingData, loading: buildingLoading } = useGetBuildingQuery({
    variables: {
      id: buildingId
    }
  })
  const building = get(buildingData, ['getBuilding'], {})

  useEffect(() => {
    if (unitId) getUnit()
  }, [])

  const navigate = useNavigate()

  const [createUnit] = useCreateFullUnitMutation()
  const [updateUnit] = useUpdateUnitMutation()
  const onSubmit = (input) => {
    const floorCells = []
    for (let row in grid) {
      const rowArr = []
      for (let col in grid[row]) {
        rowArr.push(grid[row][col] ? parseInt(grid[row][col].id ? grid[row][col].id : grid[row][col]) : '')
      }
      floorCells.push(rowArr)
    }
    if (context === 'edit') {
      updateUnit({
        variables: {
          input: {
            id: localUnit.id,
            number: input.number,
            floor: input.floor,
            zoneType: input.zoneType ? input.zoneType : null,
            alertEscalationMinutes: input.alertEscalationMinutes,
            floorCells
          }
        },
        onCompleted: (data) => {
          const updatedUnit = data.updateUnit
          setLocalUnit(updatedUnit)
          reset({
            id: updatedUnit.id,
            number: updatedUnit.number,
            floor: updatedUnit.floor,
            zoneType: updatedUnit.zoneType ? updatedUnit.zoneType : '',
            alertEscalationMinutes: updatedUnit.alertEscalationMinutes
          })
          setAlertState({
            open: true,
            severity: 'success',
            alertMessage: 'Changes applied.',
            shownFor: alertState.shownFor
          })
          setTimeout(() => (window.location.href = `/units?building_id=${buildingId}`), alertState.shownFor)
        },
        onError: (error) => {
          setAlertState({
            open: true,
            severity: 'error',
            alertMessage: error.graphQLErrors[0].message,
            shownFor: alertState.shownFor
          })
        },
        refetchQueries: ['FetchFloorCells']
      })
    } else {
      createUnit({
        variables: {
          input: {
            buildingId,
            ...input,
            zoneType: input.zoneType ? input.zoneType : null,
            floorCells
          }
        },
        onCompleted: (data) => {
          const newUnit = data.createUnit
          setLocalUnit(newUnit)
          setAlertState({
            open: true,
            severity: 'success',
            alertMessage: 'New Unit added successfully.',
            shownFor: alertState.shownFor
          })
          setTimeout(() => (window.location.href = `/units?building_id=${buildingId}`), alertState.shownFor)
        },
        onError: (error) => {
          setAlertState({
            open: true,
            severity: 'error',
            alertMessage: error.graphQLErrors[0].message
          })
        },
        refetchQueries: ['FetchFloorCells']
      })
    }
  }

  return {
    control,
    handleSubmit,
    errors,
    onSubmit,
    watch,
    localUnit,
    zoneTypes: Object.values(ZoneType),
    building
  }
}

export default useZoneCreateEditPage

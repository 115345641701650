import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Table, TableContainer, TableHead, TableBody, TableRow, Tooltip, Typography } from '@mui/material'
import { StyledTableCell } from '../../../../common/theme/components/tables/StyledTableCell'
import { StyledTableRow } from '../../../../common/theme/components/tables/StyledTableRow'

const BarWithTable = ({ datasets, timeLabels }) => {
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        stacked: false,
        stepSize: 1
      },
      x: {
        stacked: false
      }
    }
  }

  return (
    <>
      <Bar
        data={{
          labels: timeLabels,
          datasets: datasets
        }}
        options={chartOptions}
        height={'60'}
      />
      <TableContainer>
        <Table aria-label='collapsible table' sx={{ marginBottom: '1.5em' }}>
          <TableHead>
            <TableRow key='header'>
              <StyledTableCell align='left' />
              {timeLabels.map((label) => (
                <StyledTableCell align='left' key={label}>
                  {label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className='tight-table-row'>
            {datasets.map(({ label, data }, rowKey) => (
              <StyledTableRow key={rowKey}>
                <StyledTableCell component='td' scope='row' sx={{ maxWidth: 200 }} className='overflowText'>
                  <Tooltip title={<Typography fontSize={11}>{label}</Typography>}>
                    <Typography className='overflowText'>{label}</Typography>
                  </Tooltip>
                </StyledTableCell>
                {data.map((dataPoint, cellKey) => (
                  <StyledTableCell
                    key={cellKey}
                    component='td'
                    scope='row'
                    style={{
                      paddingBottom: 8,
                      paddingTop: 8
                    }}
                  >
                    {dataPoint.toLocaleString('en-us')}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default BarWithTable

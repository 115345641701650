import React, { useEffect, useState } from 'react'
import { useFetchPowerDataQuery, useFetchPowerOutliersQuery } from '../GraphQl/gql.generated'
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Tooltip
} from '@mui/material'
import { StyledTableCell } from '../../../../common/theme/components/tables/StyledTableCell'
import { isEmpty } from 'lodash'
import usePagination from '../../../../common/hooks/usePagination'
import { StyledTableRow } from '../../../../common/theme/components/tables/StyledTableRow'
import PowerChartsCard from './PowerChartsCard'
import {
  IAQ_REPORT_DISPLAY,
  ALERT_REPORT_DISPLAY,
  TEMPERATURE_REPORT_DISPLAY,
  OCCUPANCY_REPORT_DISPLAY,
  POWER_REPORT_DISPLAY,
  SOUND_REPORT_DISPLAY
} from '../../../../common/constants/reports'
import { fullDateTimeFormat, validateDate } from '../../../../common/utils/dates'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { fetchCriteria } from '../../../../common/utils/reports'
import { useFetchSensorsCollectionLazyQuery } from '../../../pages/dashboards/components/DashboardFiltersPanel/gql.generated'

const PowerSummaryCard = ({
  filterCriteria,
  setFilterCriteria,
  criteriaDisplay,
  setCriteriaDisplay,
  reportToken,
  user
}) => {
  const [totals, setTotals] = useState([])
  const [outliers, setOutliers] = useState([])
  const [outlierFilterIndex, setOutlierFilterIndex] = useState(0)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('total_kwh')
  const [groupingMethod, setGroupingMethod] = useState('day')
  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions } =
    usePagination({ numRowsPerPage: 10 })

  const {
    data: resultData,
    loading,
    refetch
  } = useFetchPowerDataQuery({
    variables: {
      filters: fetchCriteria(filterCriteria),
      reportToken,
      order,
      orderBy,
      pagination: {
        page: page + 1, // TablePagination starts count pages from zero / 0
        per: rowsPerPage
      }
    }
  })

  const {
    data: resultOutliers,
    loading: loadingOutliers,
    refetch: refetchOutliers
  } = useFetchPowerOutliersQuery({
    variables: {
      filters: fetchCriteria(filterCriteria),
      reportToken
    }
  })

  const handleRequestSort = (property) => {
    // Default sort is by descending order as clients probably want to see the highest power usage first
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  useEffect(() => {
    refetch({ order, orderBy })
  }, [order, orderBy, refetch])

  useEffect(() => {
    if (!loading) {
      setTotals(resultData.fetchPowerData.totals),
        setGroupingMethod(resultData.fetchPowerData.metadata.grouping_method)
    }
  }, [resultData, filterCriteria])

  useEffect(() => {
    refetchOutliers({ outlierFilterIndex })
  }, [outlierFilterIndex, refetchOutliers])

  useEffect(() => {
    if (!loadingOutliers) setOutliers(resultOutliers?.fetchPowerOutliers.outliers)
  }, [resultOutliers, filterCriteria])

  const attributeLabels = {
    total_kwh: 'Total Active Power Usage (kWh)',
    average_kwh: `Avg. Active Power Usage (kWh/${groupingMethod})`,
    max_kwh: `Peak Active Power Usage (kWh/${groupingMethod})`,
    total_kva: 'Total Apparent Energy Usage(kVA)',
    total_kvarh: 'Total Reactive Energy Usage (kVArh)',
    recorded_at: groupingMethod === 'hour' ? 'Peak Recorded At' : 'Peak Recorded On'
  }

  const [getSensors, { data: sensorsData, loading: sensorsLoading }] = useFetchSensorsCollectionLazyQuery()
  const triggerReportDrilldown = async (sensor_id, recorded_at) => {
    const eventTime = new Date(validateDate(recorded_at))
    const startDate = new Date(eventTime.getTime())
    startDate.setDate(startDate.getDate() - 1) // subtract a day

    const endDate = new Date(eventTime.getTime())
    endDate.setDate(endDate.getDate() + 1) // add a day

    const updatedfilterCriteria = {
      ...filterCriteria,
      sensorIds: [sensor_id.toString()],
      startDate: startDate,
      endDate: endDate,
      drilldown: true
    }
    updatedfilterCriteria[ALERT_REPORT_DISPLAY] = false
    updatedfilterCriteria[TEMPERATURE_REPORT_DISPLAY] = false
    updatedfilterCriteria[OCCUPANCY_REPORT_DISPLAY] = false
    updatedfilterCriteria[IAQ_REPORT_DISPLAY] = false
    updatedfilterCriteria[POWER_REPORT_DISPLAY] = true
    updatedfilterCriteria[SOUND_REPORT_DISPLAY] = false

    const sensors = await getSensors({
      variables: {
        companyId: user?.companyId,
        ids: [sensor_id],
        pagination: {
          per: 500
        }
      }
    })

    const unit = {
      ...sensors.data.fetchSensorsCollection.collection[0].unit,
      building: sensors.data.fetchSensorsCollection.collection[0].building
    }
    const updatedDisplayCriteria = {
      ...criteriaDisplay,
      sensors: sensors.data.fetchSensorsCollection.collection,
      units: [unit],
      buildings: [unit.building]
    }
    updatedDisplayCriteria.units[0]['building'] = updatedDisplayCriteria.buildings[0]

    setFilterCriteria(updatedfilterCriteria)
    setCriteriaDisplay(updatedDisplayCriteria)
  }

  return (
    <>
      <Paper sx={{ mt: 2, p: 4 }}>
        <h4 align={'center'}>Power Consumption Summary</h4>
        <TableContainer>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow key='header'>
                <StyledTableCell align='left'>Sensor</StyledTableCell>
                {Object.keys(attributeLabels).map((key) => (
                  <StyledTableCell align='left' key={key} sx={key === 'recorded_at' && { width: 200 }}>
                    <TableSortLabel
                      key={key + '_label'}
                      active={orderBy === key}
                      direction={orderBy === key ? order : 'desc'}
                      onClick={() => handleRequestSort(key)}
                    >
                      {attributeLabels[key]}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className='tight-table-row'>
              {!loading && (
                <>
                  {totals.map((row) => (
                    <StyledTableRow key={row['sensor_id']} hover>
                      <StyledTableCell
                        component='td'
                        scope='row'
                        sx={{ maxWidth: 425 }}
                        className='overflowText'
                      >
                        <Tooltip title={<Typography fontSize={11}>{row['sensor_name']}</Typography>}>
                          <Typography>{row['sensor_name']}</Typography>
                        </Tooltip>
                      </StyledTableCell>
                      {Object.keys(attributeLabels).map((key, index) => (
                        <StyledTableCell
                          component='td'
                          scope='row'
                          key={key + row['sensor_id']}
                          style={{
                            paddingBottom: 8,
                            paddingTop: 8
                          }}
                        >
                          {row[key]}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                  {!totals?.length && (
                    <StyledTableRow hover>
                      <StyledTableCell align='center' sx={{ verticalAlign: 'top' }} colSpan={8}>
                        <Typography component={'h4'}>No Data Available</Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </>
              )}
              {loading && (
                <TableRow hover>
                  <TableCell align='center' colSpan={8} scope='row'>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && (
            <TablePagination
              colSpan={4}
              rowsPerPageOptions={rowsPerPageOptions}
              component='div'
              count={resultData.fetchPowerData.metadata.sensorCount || 0}
              rowsPerPage={rowsPerPage}
              page={isEmpty(resultData.fetchPowerData?.totals) ? 0 : page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ paddingBottom: 25 }}
            />
          )}
        </TableContainer>
      </Paper>
      <Paper sx={{ mt: 2, p: 4 }}>
        <h4 align={'center'}>Power Outlier Data</h4>
        <TableContainer sx={{ pr: 30, pl: 30 }}>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <StyledTableCell align='left'>Sensor</StyledTableCell>
                <StyledTableCell align='left'></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className='tight-table-row'>
              {!loadingOutliers && (
                <>
                  {Object.keys(outliers).map((sensor_id, index) => (
                    // We need to make these rows clickable so users can find more information about outliers around the time the events happened
                    <StyledTableRow hover key={sensor_id}>
                      <StyledTableCell component='td' scope='row'>
                        {outliers[sensor_id].sensor_name}
                      </StyledTableCell>
                      <StyledTableCell colSpan={3}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align='left'>Peak Power Usage (kWh)</StyledTableCell>
                              <StyledTableCell align='left'>Recorded At</StyledTableCell>
                              <StyledTableCell align='left'></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className='tight-table-row'>
                            {outliers[sensor_id].data.map(({ max_kwh, recorded_at }) => (
                              <StyledTableRow>
                                <StyledTableCell component='td' scope='row'>
                                  {max_kwh}
                                </StyledTableCell>
                                <StyledTableCell component='td' scope='row'>
                                  {fullDateTimeFormat(recorded_at)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {/* Public viewers cannot drilldown, this simplifies code drastically*/}
                                  {user ? (
                                    <IconButton
                                      aria-label='search'
                                      onClick={() => triggerReportDrilldown(sensor_id, recorded_at)}
                                      sx={{ p: 0.2, m: 0.2 }}
                                    >
                                      <ArrowForwardIosIcon />
                                    </IconButton>
                                  ) : null}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  {!Object.keys(outliers).length && (
                    <StyledTableRow hover>
                      <StyledTableCell align='center' sx={{ verticalAlign: 'top' }} colSpan={8}>
                        <Typography component={'h4'}>No Data Available</Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </>
              )}
              {loadingOutliers && (
                <TableRow hover>
                  <TableCell align='center' colSpan={8} scope='row'>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <PowerChartsCard
        filterCriteria={filterCriteria}
        reportToken={reportToken}
        groupingMethod={groupingMethod}
      />
    </>
  )
}

export default PowerSummaryCard
